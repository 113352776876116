<template>
  <section class="pd-20">
    <aPageHeader class="pd-0">
      <template #title>
        <h1 class="mt-10">
          <img
            src="@/assets/images/dashboard/finances/finances.png"
            alt="ico"
            width="30"
          />
          Gerador de Faturas
        </h1>
      </template>
      <template #extra>
        <div style="padding: 10px">
          <aButton
            class="gen-btn"
            ghost
            type="primary"
            @click="openCreateTempInvoiceModal = true"
            icon="plus"
          >
            Fatura temporária
          </aButton>
        </div>
      </template>
    </aPageHeader>

    <aSpin
      v-if="isLoadingAction"
      class="loading-spin"
      size="large"
      tip="Aguarde, salvando dados..."
    />

    <aCollapse
      class="travel-filters expandable mt-10"
      expandIconPosition="right"
      v-model="activeCollapseKey"
      @change="changeActivekey"
    >
      <aCollapsePanel key="1" header="FATURAS TEMPORÁRIAS">
        <TempInvoicesSection
          v-if="!loadingTempInvoicesSection"
          @selectPaxesTempInvoices="selectPaxesTempInvoices"
        />
      </aCollapsePanel>
    </aCollapse>

    <SelectPaxesSection
      v-if="tempInvoice.id && !loadingSelectPaxesSection"
      class="mt-20"
      :tempInvoice="tempInvoice"
      :totalInvoicesInQueue="totalInvoicesInQueue"
      @getTempInvoiceDetails="getTempInvoiceDetails()"
      @onFinishGenerateInvoice="onFinishGenerateInvoice"
      @onCreateLoadTempInvoicesList="onCreateLoadTempInvoicesList"
      @onClickIsLoadingAction="onClickIsLoadingAction"
    />

    <aModal
      :width="320"
      v-model="openCreateTempInvoiceModal"
      :footer="false"
      @cancel="openCreateTempInvoiceModal = false"
    >
      <template #title> <a-icon type="plus" /> FATURA TEMPORÁRIA </template>
      <CreateTempInvoiceModal
        v-if="openCreateTempInvoiceModal"
        @onCreateLoadTempInvoicesList="onCreateLoadTempInvoicesList"
      />
    </aModal>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import contractMixins from "@/components/contracts/mixins/contractMixins";
import generateInvoicesCalcsAndRules from "@/components/finances/mixins/generateInvoicesCalcsAndRules";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import generateInvoiceMixins from "@/components/finances/mixins/generateInvoiceMixins.js";

import CreateTempInvoiceModal from "@/components/finances/modals/CreateTempInvoiceModal.vue";
import TempInvoicesSection from "@/components/finances/sections/TempInvoicesSection.vue";
import SelectPaxesSection from "@/components/finances/sections/SelectPaxesSection.vue";

export default {
  name: "SalesGenerateInvoices",
  components: {
    CreateTempInvoiceModal,
    TempInvoicesSection,
    SelectPaxesSection,
  },
  mixins: [
    formatThings,
    contractMixins,
    productSupplierMixins,
    generateInvoicesCalcsAndRules,
    generateInvoiceMixins,
  ],
  data() {
    return {
      openCreateTempInvoiceModal: false,
      loadingSelectPaxesSection: false,
      loadingTempInvoicesSection: false,
      activeCollapseKey: "1",
      totalInvoicesInQueue: 0,
      tempInvoice: {},
      isLoadingAction: false,
    };
  },

  methods: {
    onClickIsLoadingAction(status) {
      this.isLoadingAction = status;
    },
    changeActivekey() {
      this.activeCollapseKey = this.activeCollapseKey === "1" ? "0" : "1";
    },
    onFinishGenerateInvoice() {
      this.tempInvoice = {};
    },
    async getTempInvoiceDetails() {
      this.loadingTempInvoicesSection = true;
      try {
        const { data } = await this.$http.get(
          `/temporary-invoice/details?id=${this.tempInvoice.id}`
        );

        this.selectPaxesTempInvoices(data, true);
        return data;
      } catch (e) {
        e;
      } finally {
        this.loadingTempInvoicesSection = false;
      }
    },
    onCreateLoadTempInvoicesList() {
      this.openCreateTempInvoiceModal = false;
      this.loadingTempInvoicesSection = true;
      setTimeout(() => {
        this.loadingTempInvoicesSection = false;
      }, 500);
    },
    selectPaxesTempInvoices(tempInvoice, noLoad) {
      this.loadingSelectPaxesSection = noLoad ? false : true;

      this.activeCollapseKey = "2";

      this.totalInvoicesInQueue =
        tempInvoice.selected_paxes !== ""
          ? JSON.parse(tempInvoice.selected_paxes).length
          : 0;

      setTimeout(() => {
        this.tempInvoice = tempInvoice;
        this.loadingSelectPaxesSection = false;
      }, 500);
    },
  },
};
</script>

<style lang="sass" scoped>
.fly-add-bills
  position: fixed
  bottom: 30px
  right: 30px
  z-index: 10
  button
    padding: 10px
    height: 60px
.generate-invoice-btn
  position: fixed
  top: 80px
  right: 27px
  z-index: 10
  .close-btn
    border-color: red !important
    color: red
    background: #fff !important
  .gen-btn
    background: #fff !important
  .selected
    background: #fff !important
    border-width: 2px
</style>

<style>
.loading-spin {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 300px 50px;
  z-index: 99999;
  color: #fff;
}

.loading-spin .ant-spin-text {
  margin-top: 20px !important;
  font-size: 18px;
}

.loading-spin .ant-spin-dot {
  font-size: 62px;
}

.loading-spin .ant-spin-dot-item {
  background: #fff !important;
  width: 24px !important;
  height: 24px !important;
}
</style>

<style lang="sass">
.field
  position: relative
  padding-top: 10px
  label
    font-size: 11px
    color: #999
  .label
    position: absolute
    font-size: 10px
    font-weight: 600
    color: #434a53 !important
    top: -10px
    z-index: 1
.travel-filters
  .field.payments
    .ant-checkbox-wrapper
      margin: 0 !important
      padding: 4px 11px 0px 0
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
.sale-outer-modal
  .ant-modal-close
    background: #cbcbcb!important
    border-radius: 0 0 0 5px
    .ant-modal-close-x
      width: 26px
      height: 18px
      font-size: 12px
      line-height: 19px
  .ant-modal-body
    padding: 0


.contract-details
  .ant-modal-close
    top: -48px
    padding: 0 !important
    height: 20px
    width: 20px
    color: #FFF !important
    right: 16px
  .ant-modal-body
    padding: 0
</style>
